import { useState, useEffect } from "react";
import { Frame, Loading, Header } from "../components";
import { db, auth } from "../config/firebase";
import { doc, getDoc } from "firebase/firestore";
import { ValidaLinha } from "../components/Validacao";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import Logo from "../assets/logo2.png";
import moment from "moment";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [dados, setDados] = useState({});

  let uid = auth.currentUser?.uid;

  const navigate = useNavigate();

  const checkSaldo = (data) => {
    const hoje = moment().format("YYYY-MM-DD");

    if (data?.dtEmbarque !== hoje) return data?.limite || 0;
    else return data?.saldo || data?.limite;
  };

  const getData = async () => {
    const qry = await getDoc(doc(db, "passe2024", uid));

    const data = qry.data();

    const saldo = checkSaldo(data);

    setDados({ ...data, saldo: saldo });
    setLoading(false);
  };

  const checkLogin = async () => {
    if (uid) getData();
    else
      onAuthStateChanged(auth, (user) => {
        if (user?.uid) {
          uid = user.uid;
          getData();
        } else navigate("../login");
      });
  };

  useEffect(() => {
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header nome={dados?.nome} foto={dados?.urlFoto} />

      {loading && <Loading />}

      {!loading && !dados?.status && <Novo />}
      {!loading && dados?.status === "a" && <Aprovado dados={dados} />}
      {!loading && dados?.status === "i" && <Inativo />}
      {!loading && dados?.status === "e" && <Incorreto dados={dados} />}
      {!loading && dados?.status === "p" && <Pendente />}
      {!loading && dados?.status === "n" && <NaoPossui />}
    </>
  );
};

export default Home;

const Aprovado = (props) => {
  return (
    <Frame
      body={
        <div className="container-fluid">
          <div className="col-sm-6 mx-auto">
            <img className="mt-2" src={Logo} width={250} alt="logo" />
            <h4 className="mt-2 azul-logo fw-bolder">PASSE LIVRE EMBAIXADOR</h4>

            <div className="mt-3">
              <QRCode size={150} value={"378|" + props.dados.uid} />
            </div>

            <img
              src={props.dados.urlFoto}
              alt="Usuário"
              width="150"
              height="150"
              className="d-inline rounded-circle mt-3"
            />

            <div className="mt-4">
              <h5 className="px-3 py-1 azul-bg bg-gradient text-white rounded-3">
                {props.dados.nome}
              </h5>
            </div>

            <div className="my-3 mx-auto">
              <div className="mt-2">
                <span className="fw-bold azul-logo">Carteira: </span>
                {props.dados.contrato}
              </div>
              <div className="mt-2">
                <span className="fw-bold azul-logo">Créditos: </span>
                {props.dados.saldo}
              </div>

              {props.dados.documento && (
                <div className="mt-2">
                  <span className="fw-bold azul-logo">Doc: </span>
                  {props.dados.documento}
                </div>
              )}

              <div className="mt-2">
                <span className="fw-bold azul-logo">Vencimento: </span>
                {moment(props.dados.validade).format("DD/MM/YYYY")}
              </div>
              {props.dados.linha1 && (
                <div className="fw-bold azul-logo mt-2">
                  {ValidaLinha(props.dados.linha1)}
                </div>
              )}
              {props.dados.linha2 && (
                <div className="fw-bold azul-logo mt-2">
                  {ValidaLinha(props.dados.linha2)}
                </div>
              )}
              {props.dados.linha3 && (
                <div className="fw-bold azul-logo mt-2">
                  {ValidaLinha(props.dados.linha3)}
                </div>
              )}
              {props.dados.info && (
                <div className="fw-bold azul-logo mt-2">{props.dados.info}</div>
              )}
            </div>
          </div>
        </div>
      }
    />
  );
};

const Novo = () => {
  return (
    <Frame
      body={
        <div>
          <p>
            Preencha seu cadastro para receber sua carteira digital de passe
            livre da Expresso Embaixador.
          </p>
          <div className="mt-4 text-end">
            <a className="btn btn-primary" href="./form1">
              Preencha aqui
            </a>
          </div>
        </div>
      }
      title="Finalize seu cadastro"
    />
  );
};

const Pendente = () => {
  return (
    <Frame
      body={
        <div>
          <p>Estamos validando as informações enviadas.</p>
          <p>
            Caso haja alguma inconformidade nos dados cadastrados, solicitaremos
            a correção pelo portal.
          </p>

          <div className="alert alert-danger" role="alert">
            Por favor, acesse seu login novamente no próximo dia útil
          </div>
        </div>
      }
      title="Cadastro em Análise"
    />
  );
};

const Incorreto = (props) => {
  return (
    <Frame
      body={
        <div>
          <p>
            Encontramos algumas informações incorretas na validação de seu
            cadastro.
          </p>
          <p>Por gentileza, corrija essas informações.</p>

          <div className="alert alert-danger my-3" role="alert">
            {props.dados.observacao}
          </div>

          <div className="mt-4 text-end">
            <a className="btn btn-primary" href="./form1">
              Corrigir
            </a>
          </div>
        </div>
      }
      title="Cadastro Incorreto"
    />
  );
};

const Inativo = () => {
  return (
    <Frame
      body={
        <div>
          <p>Seu cadastro encontra-se inativo.</p>
          <p>Para reativá-lo, entre em contato conosco.</p>
          <p>Whatsapp: (53) 99125-5743</p>
        </div>
      }
      title="Cadastro Inativo"
    />
  );
};

const NaoPossui = () => {
  return (
    <Frame
      body={
        <div>
          <p>Seu cadastro não possui direito ao benefício do passe livre.</p>
          <p>Para maiores informações entre em contato conosco.</p>
          <p>Whatsapp: (53) 99125-5743</p>
        </div>
      }
      title="Cadastro Cancelado"
    />
  );
};
